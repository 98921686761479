<template>
  <LaporanManajemenForm mode="Ubah" module="Laporan Manajemen"> </LaporanManajemenForm>
</template>

<script>
import LaporanManajemenForm from './form';

const LaporanManajemenUpdate = {
  name: 'LaporanManajemenUpdate',
  components: { LaporanManajemenForm },
};

export default LaporanManajemenUpdate;
</script>
