<template>
  <RecordViewWrapper>
    <sdPageHeader :title="pageData.title">
      <template #subTitle>
        <sdButton
          class="btn-add_new"
          size="default"
          type="primary"
          v-if="checkPermission('create laporan manajemen ' + pageData.title.toLowerCase())"
        >
          <router-link :to="'/laporan-manajemen/laporan-manajemen-target-realisasi-add/' + $route.params.id">
            <sdFeatherIcons type="plus" size="14" /> Tambah</router-link
          >
        </sdButton>
        <sdButton class="btn-add_new" size="default" type="info">
          <router-link :to="'/laporan-manajemen/laporan-manajemen-target-realisasi-triwulan/' + $route.params.id">
            <sdFeatherIcons type="file-text" size="14" /> Rekap Laporan Triwulan</router-link
          >
        </sdButton>
      </template>
      <template #buttons>
        <div class="search-box">
          <span class="search-icon">
            <sdFeatherIcons type="search" size="14" />
          </span>
          <a-input
            @change="handleSearch"
            v-model:value.trim="formState.searchItem"
            type="text"
            name="recored-search"
            placeholder="Search Here"
          /></div
      ></template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table
                  :pagination="{ pageSize: 10, showSizeChanger: true }"
                  :dataSource="dataSource"
                  :columns="columns.data"
                  bordered
                />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>

      <a-modal v-model:visible="modalState.visible" title="Konfirmasi">
        <a-form
          layout="horizontal"
          :model="formModalState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="modalRules"
          id="unapproveForm"
          @finish="doUnapprove"
        >
          Apakah Anda yakin ingin melakukan unapprove {{ modalState.name }}?
          <a-form-item name="alasan" label="Alasan">
            <a-input v-model:value="formModalState.alasan" placeholder="Masukkan Alasan" />
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button key="back" @click="handleCancel">Batal</a-button>
          <a-button class="sDash_form-action__btn" type="primary" size="large" htmlType="submit" form="unapproveForm">
            {{ modalState.isLoading ? 'Harap Menunggu...' : 'Unapprove' }}
          </a-button>
        </template>
      </a-modal>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main, TableWrapper } from '../../styled';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { DataService } from '@/config/dataService/dataService';
import { getItem } from '@/utility/localStorageControl';

const columns = reactive({
  data: [],
});

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);
    // const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const { params } = useRoute();
    const profile = getItem('profile_plnbb');
    const permissions = getItem('permissions');

    const modalState = reactive({
      isLoading: false,
      visible: false,
      kpi_korporat: '',
      month: '',
      year: null,
      type: '',
    });

    const formModalState = reactive({
      id: null,
      alasan: '',
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const pageData = reactive({
      title: ' ',
    });

    const renderContent = row => {
      return {
        children:
          row.text && !isNaN(row.text)
            ? parseFloat(row.text)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : row.text,
      };
    };

    onMounted(() => {
      getData();
    });

    const getData = () => {
      dispatch('axiosCrudGetData', 'laporan-manajemen-target-realisasi/' + parseInt(params.id)).then(() => {
        pageData.title = crud.value.data.name;

        dispatch('axiosCrudGetData', `laporan-manajemen-target-realisasi/${parseInt(params.id)}/index`).then(() => {
          columns.data = [
            {
              title: 'Tahun',
              dataIndex: 'year',
              key: 'year',
            },
            {
              title: 'Keterangan',
              dataIndex: 'description',
              key: 'description',
            },
          ];

          crud.value.variables.forEach(data => {
            columns.data.push({
              title: data.name,
              dataIndex: 'target',
              key: 'target',
              children: [
                {
                  title: 'Target',
                  dataIndex: 'target_' + data.id,
                  key: 'target_' + data.id,
                  align: 'right',
                  customRender: renderContent,
                },
                {
                  title: 'Realisasi',
                  dataIndex: 'realisasi_' + data.id,
                  key: 'realisasi_' + data.id,
                  align: 'right',
                  customRender: renderContent,
                },
                {
                  title: 'Status',
                  dataIndex: 'status_' + data.id,
                  key: 'status_' + data.id,
                },
                {
                  title: '',
                  dataIndex: 'action_' + data.id,
                  key: 'action_' + data.id,
                },
              ],
            });
          });

          columns.data.push({
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            width: '90px',
          });
        });
      });
    };

    const handleDelete = (item_id, year) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `apakah anda yakin ingin menghapus Laporan Manajemen Target Realisasi ${year}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id: item_id,
            url: 'laporan-manajemen-target-realisasi',
            getData: () => {
              dispatch('axiosCrudGetData', `laporan-manajemen-target-realisasi/${parseInt(params.id)}/index`);
            },
          });
        },
      });
    };

    const formState = reactive({
      searchItem: '',
    });

    const dataSource = computed(() => {
      if (crud.value && crud.value.dataItems && crud.value.variables) {
        let rowTotal = {
          key: null,
          year: null,
          description: 'TOTAL',
        };
        let pencapaian = {
          key: null,
          year: null,
          description: null,
        };

        let update = dataItem => {
          checkPermission('update laporan manajemen ' + pageData.title.toLowerCase()) ? (
            <router-link
              class="edit"
              to={`/laporan-manajemen/laporan-manajemen-target-realisasi-edit/${params.id}/${dataItem.item_id}`}
              style="margin-right:12px;"
            >
              <sdFeatherIcons type="edit" size={14} title="Ubah" />
            </router-link>
          ) : null;
        };

        let del = dataItem => {
          checkPermission('delete laporan manajemen ' + pageData.title.toLowerCase()) ? (
            <router-link class="delete" onClick={() => handleDelete(dataItem.item_id)} to="">
              <sdFeatherIcons type="trash-2" size={14} title="Hapus" />
            </router-link>
          ) : null;
        };

        let listItems = crud.value.dataItems.map((dataItem, key) => {
          let sources = {
            key: key + 1,
            year: dataItem.year,
            description: dataItem.description,
            action: (
              <div class="table-actions">
                {update(dataItem)}
                {del(dataItem)}
              </div>
            ),
          };

          crud.value.variables.forEach(variable => {
            let color;
            let status = dataItem.nilaiLaporans[variable.id].status;

            switch (status) {
              case 'CRTD':
                color = 'blue';
                break;
              case 'UPDT':
                color = 'orange';
                break;
              case 'REL':
                color = 'green';
                break;
              case 'UNREL':
                color = 'volcano';
                break;
              case 'DEL':
                color = 'red';
                break;
            }

            let approve = checkPermission('approve laporan manajemen ' + pageData.title.toLowerCase()) ? (
              <a
                class="edit"
                onClick={() =>
                  handleApprove(
                    dataItem.nilaiLaporans[variable.id].nilai_id,
                    dataItem.nilaiLaporans[variable.id].variable_name,
                  )
                }
              >
                <sdFeatherIcons type="check-square" size={14} title="Approve" />
              </a>
            ) : null;

            let unapprove = checkPermission('approve laporan manajemen ' + pageData.title.toLowerCase()) ? (
              <a
                class="edit"
                onClick={() =>
                  handleUnapprove(
                    dataItem.nilaiLaporans[variable.id].nilai_id,
                    dataItem.nilaiLaporans[variable.id].variable_name,
                  )
                }
              >
                <sdFeatherIcons type="rotate-ccw" size={14} title="Unapprove" />
              </a>
            ) : null;

            sources[`target_${variable.id}`] = dataItem.nilaiLaporans[variable.id].nilai_target;
            sources[`status_${variable.id}`] = status ? <a-tag color={color}>{status}</a-tag> : '';
            sources[`realisasi_${variable.id}`] = dataItem.nilaiLaporans[variable.id].nilai_realisasi;
            sources[`action_${variable.id}`] = status != 'REL' ? { approve } : { unapprove };

            if (rowTotal[`target_${variable.id}`]) {
              rowTotal[`target_${variable.id}`] += parseFloat(dataItem.nilaiLaporans[variable.id].nilai_target) || 0;
              rowTotal[`realisasi_${variable.id}`] +=
                parseFloat(dataItem.nilaiLaporans[variable.id].nilai_realisasi) || 0;
            } else {
              rowTotal[`target_${variable.id}`] = parseFloat(dataItem.nilaiLaporans[variable.id].nilai_target) || 0;
              rowTotal[`realisasi_${variable.id}`] =
                parseFloat(dataItem.nilaiLaporans[variable.id].nilai_realisasi) || 0;
            }

            if (rowTotal[`realisasi_${variable.id}`] > 0) {
              pencapaian[`realisasi_${variable.id}`] =
                (rowTotal[`realisasi_${variable.id}`] / rowTotal[`target_${variable.id}`]) * 100;
              pencapaian[`target_${variable.id}`] = 'Pencapaian (%)';
            }
          });
          return sources;
        });
        rowTotal.key = listItems.length + 1;
        listItems.push(rowTotal);
        pencapaian.key = listItems.length + 1;
        listItems.push(pencapaian);
        return listItems;
      } else {
        return [];
      }
    });

    const handleSearch = () => {
      dispatch('axiosDataSearch', {
        url: 'laporan-laporan-manajemen/' + parseInt(params.id),
        filter: formState.searchItem,
      });
    };

    const handleApprove = (id, name) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin melakukan laporan manajemen ${name}?`,
        okText: 'Approve',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataApprove', {
            id,
            url: 'approve-laporan-manajemen-target-realisasi',
            getData: () => {
              getData();
            },
          });
        },
      });
    };

    const handleUnapprove = (id, name) => {
      formModalState.id = id;
      modalState.name = name;
      modalState.visible = true;
    };

    const handleCancel = () => {
      getData();
      modalState.visible = false;
    };

    const doUnapprove = () => {
      modalState.isLoading = true;

      DataService.post('unapprove-laporan-manajemen-target-realisasi/' + formModalState.id, {
        alasan: formModalState.alasan,
      }).then(() => {
        modalState.visible = false;
        modalState.isLoading = false;
        formModalState.id = null;
        formModalState.alasan = '';

        getData();
      });
    };

    const modalRules = {
      alasan: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const checkPermission = permission => {
      if (profile.roles[0].name == 'Super Admin') {
        return true;
      }

      return permissions.includes(permission);
    };

    return {
      formState,
      columns,
      isLoading,
      crud,
      dataSource,
      handleDelete,
      handleSearch,
      labelCol,
      wrapperCol,
      pageData,
      handleApprove,
      handleUnapprove,
      handleCancel,
      doUnapprove,
      modalState,
      formModalState,
      modalRules,
      checkPermission,
    };
  },
};

export default ViewPage;
</script>
