<template>
  <sdPageHeader :title="pageData.title">
    <template #subTitle>
      <sdButton class="btn-add_new" size="default" type="secondary">
        <router-link :to="'/laporan-manajemen/laporan-manajemen-target-realisasi/' + $route.params.id + '/index'">
          <sdFeatherIcons type="chevron-left" size="14" /> Kembali / Batal</router-link
        >
      </sdButton>
    </template>
  </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="right"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="year" label="Tahun">
            <a-input-number v-model:value="formState.year" placeholder="Masukkan Tahun" />
          </a-form-item>
          <a-form-item name="description" label="Keterangan">
            <a-input v-model:value="formState.description" placeholder="Masukkan Keterangan" />
          </a-form-item>

          <template v-for="variable in variables.data" :key="variable.id">
            <a-form-item :label="variable.name">
              <a-row>
                <a-col :span="6" style="margin-right:10px;">
                  <a-input-number
                    :name="'target[' + variable.id + ']'"
                    v-if="variable.jenis_variable_laporan_manajemen_target_realisasi.jenis == 'number'"
                    v-model:value="formState.variables.target[variable.id]"
                    :placeholder="'Masukkan Target ' + variable.name"
                    :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                    @change="handleHitungPencapaian(variable.id)"
                  />
                </a-col>
                <a-col :span="6" style="margin-right:10px;">
                  <a-input-number
                    :name="'realisasi[' + variable.id + ']'"
                    v-if="variable.jenis_variable_laporan_manajemen_target_realisasi.jenis == 'number'"
                    v-model:value="formState.variables.realisasi[variable.id]"
                    :placeholder="'Masukkan Realisasi ' + variable.name"
                    :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                    @change="handleHitungPencapaian(variable.id)"
                  />
                </a-col>
                <a-col :span="4">
                  <a-input-number
                    v-model:value="formState.variables.pencapaian[variable.id]"
                    :placeholder="'Pencapaian ' + variable.name"
                    :formatter="value => `${value}%`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    :parser="value => value.replace(/\%\s?|(,*)/g, '')"
                    disabled
                  />
                </a-col>
              </a-row>
            </a-form-item>
          </template>
          <a-row>
            <a-col :lg="{ span: 20, offset: 4 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link :to="`/laporan-manajemen/laporan-manajemen-target-realisasi/${$route.params.id}/index`">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted, toRaw } from 'vue';
import { useRoute } from 'vue-router';

const LaporanManajemenForm = {
  name: 'LaporanManajemenForm',
  components: { HorizontalFormStyleWrap, Main },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);
    // const dateObj = new Date();
    // const month = dateObj.getUTCMonth() + 1;

    const pageData = reactive({
      title: ' ',
    });

    const formState = reactive({
      year: '',
      description: '',
      variables: {
        target: [],
        realisasi: [],
        pencapaian: [],
      },
    });

    const variables = reactive({
      data: {},
    });

    const labelCol = {
      lg: 4,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 20,
      md: 15,
      xs: 24,
    };

    const handleSubmit = () => {
      let data = toRaw(formState);

      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitData', {
          url: 'laporan-manajemen-target-realisasi',
          data: data,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdate', {
          id: params.itemId,
          url: 'laporan-manajemen-target-realisasi',
          data: data,
        });
      }
    };

    const handleHitungPencapaian = varId => {
      let target = formState.variables.target[varId];
      let realisasi = formState.variables.realisasi[varId];
      formState.variables.pencapaian[varId] = hitungPencapaian(target, realisasi);
    };

    const hitungPencapaian = (target, realisasi) => {
      if (isNaN(parseFloat(target)) || isNaN(parseFloat(realisasi))) {
        return null;
      }
      let result = (parseFloat(realisasi) / parseFloat(target)) * 100.0;
      return result.toFixed(2);
    };

    /** */
    const rules = {
      // year: [
      //   {
      //     required: true,
      //     message: 'Harap Diisi',
      //     trigger: 'blur',
      //   },
      // ],
    };

    onMounted(() => {
      formState.laporanId = parseInt(params.id);
      dispatch('axiosCrudGetData', `laporan-manajemen-target-realisasi/${parseInt(params.id)}`).then(() => {
        pageData.title = props.mode + ' ' + crud.value.data.name;
      });

      dispatch('axiosCrudGetData', `variable-laporan-manajemen-target-realisasi/${parseInt(params.id)}`).then(() => {
        variables.data = crud.value;
      });

      if (props.mode == 'Ubah') {
        dispatch('axiosCrudGetData', `laporan-manajemen-target-realisasi/${parseInt(params.itemId)}/edit`).then(() => {
          crud.value.data.nilais.forEach(data => {
            formState.variables.target[data.variable_id] = data.nilai_target;
            formState.variables.realisasi[data.variable_id] = data.nilai_realisasi;
            formState.variables.pencapaian[data.variable_id] = hitungPencapaian(
              data.nilai_target,
              data.nilai_realisasi,
            );
          });
          console.log(formState.variables);
          formState.year = crud.value.data.year;
          formState.description = crud.value.data.description;
        });
      }
    });

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      handleHitungPencapaian,
      isLoading,
      rules,
      variables,
      pageData,
    };
  },
};

export default LaporanManajemenForm;
</script>
