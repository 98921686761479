<template>
  <RecordViewWrapper>
    <sdPageHeader :title="'Laporan Triwulan ' + pageData.title">
      <template #subTitle>
        <sdButton class="btn-add_new" size="default" type="secondary">
          <router-link :to="'/laporan-manajemen/laporan-manajemen-target-realisasi/' + $route.params.id + '/index'">
            <sdFeatherIcons type="chevron-left" size="14" /> Kembali</router-link
          >
        </sdButton>
      </template>
      <template #buttons>
        <a-form-item label="Pilih Tahun" has-feedback>
          <a-select default-value="" @change="handleChangeYear" style="width: 150px">
            <a-select-option value="">
              Semua
            </a-select-option>
            <a-select-option v-for="tahun in formState.tahuns" :key="tahun">
              {{ tahun }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table
                  :pagination="{ pageSize: 10, showSizeChanger: true }"
                  :dataSource="dataSource"
                  :columns="columns.data"
                  bordered
                />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main, TableWrapper } from '../../styled';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';

const columns = reactive({
  data: [],
});

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);
    // const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const { params } = useRoute();

    const formState = reactive({
      tahun: '',
    });

    const pageData = reactive({
      title: ' ',
    });

    const renderContent = row => {
      return {
        children: row.text
          ? parseFloat(row.text)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : '',
      };
    };

    onMounted(() => {
      dispatch('axiosCrudGetData', `laporan-manajemen-target-realisasi-triwulan/${parseInt(params.id)}/preparing`).then(
        () => {
          pageData.title = crud.value.title.name;
          formState.tahuns = crud.value.tahuns;

          dispatch('axiosCrudGetData', `laporan-manajemen-target-realisasi-triwulan/${parseInt(params.id)}`).then(
            () => {
              const headerColumns = ['TW I', 's.d TW II', 's.d TW III', 's.d TW IV'];
              columns.data = [
                {
                  title: 'Tahun',
                  dataIndex: 'year',
                  key: 'year',
                },
                {
                  title: 'Keterangan',
                  dataIndex: 'description',
                  key: 'description',
                },
              ];

              for (let index = 0; index < headerColumns.length; index++) {
                const headerText = headerColumns[index];
                let formatColumn = {
                  title: headerText,
                  dataIndex: 'description',
                  key: 'description',
                  children: [
                    {
                      title: 'Target',
                      dataIndex: 'target_tw' + (index + 1),
                      key: 'target_tw' + (index + 1),
                      align: 'right',
                      customRender: renderContent,
                    },
                    {
                      title: 'Realisasi',
                      dataIndex: 'realisasi_tw' + (index + 1),
                      key: 'realisasi_tw' + (index + 1),
                      align: 'right',
                      customRender: renderContent,
                    },
                  ],
                };
                columns.data.push(formatColumn);
              }
            },
          );
        },
      );
    });

    const handleDelete = (item_id, year) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `apakah anda yakin ingin menghapus Laporan Manajemen Target Realisasi ${year}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id: item_id,
            url: 'laporan-manajemen-target-realisasi',
            getData: () => {
              dispatch('axiosCrudGetData', `laporan-manajemen-target-realisasi/${parseInt(params.id)}/index`);
            },
          });
        },
      });
    };

    const dataSource = computed(() => {
      if (crud.value && crud.value.dataItems) {
        // let rowTotal = {
        //   key: null,
        //   year: 'TOTAL',
        //   description: null,
        // };

        // mapping data response
        let listItems = crud.value.dataItems.map((dataItem, key) => {
          let sources = {
            key: key + 1,
            year: dataItem.tahun,
            description: dataItem.keterangan,
            target_tw1: dataItem.target_tw1,
            target_tw2: dataItem.target_tw2,
            target_tw3: dataItem.target_tw3,
            target_tw4: dataItem.target_tw4,
            realisasi_tw1: dataItem.realisasi_tw1,
            realisasi_tw2: dataItem.realisasi_tw2,
            realisasi_tw3: dataItem.realisasi_tw3,
            realisasi_tw4: dataItem.realisasi_tw4,
          };

          for (let item in listItems) {
            console.log(item);
          }
          return sources;
        });
        // rowTotal.key = listItems.length + 1;
        // listItems.push(rowTotal);
        return listItems;
      } else {
        return [];
      }
    });

    const handleChangeYear = tahun => {
      dispatch('axiosDataSearch', {
        url: `laporan-manajemen-target-realisasi-triwulan/${parseInt(params.id)}/${tahun}`,
      });
    };

    return {
      formState,
      columns,
      isLoading,
      crud,
      dataSource,
      handleDelete,
      handleChangeYear,
      pageData,
    };
  },
};

export default ViewPage;
</script>
