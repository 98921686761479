<template>
  <LaporanManajemenForm mode="Tambah" module="Laporan Manajamen"> </LaporanManajemenForm>
</template>

<script>
import LaporanManajemenForm from './form';

const LaporanManajemenAdd = {
  name: 'LaporanManajemenAdd',
  components: { LaporanManajemenForm },
};

export default LaporanManajemenAdd;
</script>
